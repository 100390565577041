import {utils} from "./utils";

/**
 * Filters
 * @type {Element}
 */
const filtersForm = document.querySelector('.filters-form');

const filters = document.querySelectorAll('.filter');

filters.forEach((el) => {

	// apre chiude il singolo filtro
	el.addEventListener('click', function (e) {
		e.stopPropagation();

		if (!e.target.classList.contains('filter__title'))
		{
			return false;
		}

		const filter = this;

		// reset other open filters
		const openFilters = document.querySelectorAll('.filter--open');

		openFilters.forEach((openFilter) => {
			if (openFilter !== filter)
			{
				openFilter.classList.remove('filter--open')
			}
		});

		if (filter.classList.contains('filter--open'))
		{
			// close filter
			filter.classList.remove('filter--open');
		}
		else
		{
			// open filter
			filter.classList.add('filter--open');
		}

		if (document.querySelectorAll('.filter--open').length > 0)
		{
			document.body.classList.add('filter-open');
		}
		else
		{
			setTimeout(function () {
				document.body.classList.remove('filter-open');
			}, 300);
		}

		filter.querySelector('.filter__values').removeAttribute('style');
	});
});


const filterValues = document.querySelectorAll('.filter li');

filterValues.forEach((el) => {
	el.addEventListener('click', function () {
		const filterValue = this;
		const filter = utils.parents(filterValue, '.filter')[0];
		const selectedValue = filterValue.dataset.value;

		const select = filter.querySelector('select');
		const selectedOption = select.querySelector('option[value="' + selectedValue + '"]');

		if (selectedOption.selected === true)
		{
			selectedOption.selected = false;
			filterValue.classList.remove('selected');
		}
		else
		{
			selectedOption.selected = true;
			filterValue.classList.add('selected');
		}

		//filtersForm.submit();
	});
});

const filtersReset = document.querySelector('.filters-container__reset');

if (filtersReset !== null)
{
	filtersReset.addEventListener('click', function () {
		const selects = filtersForm.querySelectorAll('select');

		selects.forEach((select) => {
			select.selectedIndex = -1;
		});

		const hidden = filtersForm.querySelectorAll('input[type="hidden"]');

		hidden.forEach((input) => {
			input.value = '';
		});

		filtersForm.submit();
	});
}

const filtersCancel = document.querySelectorAll('.filter__cancel');

filtersCancel.forEach((el) => {
	el.addEventListener('click', function () {
		document.querySelector('.filter--open').classList.remove('filter--open');

		setTimeout(function () {
			document.body.classList.remove('filter-open');
		}, 300);
	});
});

const filtersContainerTitle = document.querySelector('.filters-container__title');

if (filtersContainerTitle !== null)
{
	filtersContainerTitle.addEventListener('click', function () {
		utils.slideToggle(filtersForm);
		filtersContainerTitle.classList.toggle('filters-container__title--active');
	});
}